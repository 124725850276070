<template>
  <div 
    class="fusion-super-save__panel"
    @click.stop="onSuperSavePanelClick"
  >
    <header class="fusion-super-save__header">
      <i class="fusion-super-save__logo"></i>
      <div
        class="fusion-super-save__btn-wrapper"
      >
        <div
          v-if="marketingInfo?.labelText"
          class="fusion-super-save__tooltip"
        >
          {{ marketingInfo.labelText }}
        </div>
        <a
          v-if="marketingInfo?.btnText"
          href="javascript:void(0);"
          class="fusion-super-save__btn"
        >{{ marketingInfo.btnText }}</a>
      </div>
    </header>
    <main
      class="fusion-super-save__main"
    >
      <div
        v-if="showCoupons?.length"
        class="fusion-super-save__coupon-list"
      >
        <div
          v-for="coupon in showCoupons"
          :key="coupon.couponCode"
          class="fusion-super-save__coupon"
          :class="[
            `fusion-super-save__coupon-${computedStyles.couponWidthRem}`,
            coupon.hasMoreCoupons ? 'fusion-super-save__more-coupons' : '',
          ]"
        >
          <div class="fusion-super-save__coupon-content">
            <span
              v-if="coupon.count > 1"
              class="fusion-super-save__coupon-badge"
            >{{ GB_cssRight ? `${coupon.count} x` : `x ${coupon.count}` }}</span>
            <div class="fusion-super-save__coupon-discount-wrapper">
              <s-adapter-text
                class="fusion-super-save__coupon-discount"
                :class="{
                  'free-shipping-coupon': coupon.isFreeShippingCoupon,
                  'no-countdown-style': !showCouponCountdown(coupon),
                }"
                :min-size="12"
                :lines="showCouponCountdown(coupon) ? 1 : 2"
                :text="coupon.discountText"
              />
            </div>
            <CouponCountdown
              v-if="showCouponCountdown(coupon)"
              class="fusion-super-save__coupon-countdown"
              :seconds="getCountDownSeconds(coupon)"
            />
          </div>
        </div>
      </div>
      <div
        v-else-if="savedTextHtml"
        class="fusion-super-save__coupon-list"
      >
        <div
          class="fusion-super-save__coupon-content fusion-super-save__saved-text"
        >
          <span v-html="savedTextHtml"></span>
        </div>
      </div>
      <p
        class="fusion-super-save__desc"
        v-html="titleHtml"
      ></p>
    </main>
  </div>
</template>

<script setup name="NewSuperSavePanel">
import { ref, computed, getCurrentInstance } from 'vue'
import { AdapterText as SAdapterText } from '@shein/sui-mobile'
import CouponCountdown from './CouponCountdown.vue'
import { SuperSaveCouponStatus } from 'public/src/pages/user/child_pages/dashboard/service/constants'
import { daEventCenter } from 'public/src/services/eventCenter'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils'

const props = defineProps({
  superSaveData: {
    type: Object,
    default: () => ({}),
  },
  computedStyles: {
    type: Object,
    default: () => ({}),
  },
  reportParams: {
    type: Object,
    default: () => ({}),
  },
})

const vm = getCurrentInstance()
const GB_cssRight = ref(gbCommonInfo?.GB_cssRight)
const getCountDownSeconds = (coupon) => {
  const { currentTime } = props.superSaveData || {}
  if (currentTime && coupon?.couponStatus === SuperSaveCouponStatus.TO_USE && coupon?.expireAt) {
    return coupon.expireAt - currentTime
  }
}
const showCouponCountdown = coupon => {
  const countDownSeconds = getCountDownSeconds(coupon)
  return countDownSeconds && countDownSeconds < 24 * 3600
}
const highlightNumberText = text => (text || '').replace(
  /@@([^@]+)@@/,
  (_, $1) => `<span style="font-family: 'SF-UI-Text-Bold';color: #F82854;font-weight: 700;">${$1}</span>`
).replace(/@@/g, '')
const showCoupons = computed(() => props.computedStyles?.showCoupons || [])
const marketingInfo = computed(() => props.superSaveData?.marketingInfo)
const titleHtml = computed(() => highlightNumberText(marketingInfo.value?.titleText || ''))

const savedTextHtml = computed(() => highlightNumberText(props.superSaveData.savedText))

const onSuperSavePanelClick = () => {
  const { marketingInfo } = props.superSaveData || {}
  setTimeout(() => {
    const { langPath } = gbCommonInfo
    // TODO: supersave
    const isLogin = isLoginFn()
    const toDetailPage = () => {
      const jumpUrl = `${langPath}/${marketingInfo?.btnJumpUrl || 'user/sheinsaver?productsource=me'}`
      vm.proxy.$router.push(jumpUrl)
    }
    if (isLogin) {
      toDetailPage()
    } else {
      SHEIN_LOGIN.show({
        show: true,
        bi: 'saver',
        cb: () => {
          toDetailPage()
        }
      })
    }
  }, 300)
  daEventCenter.triggerNotice({
    daId: '1-25-2-30',
    bindData: props.reportParams,
  })
}
</script>

<style lang="less" scoped>
.fusion-super-save {
  &__panel {
    height: 80/37.5rem;
    border: 0.5px solid rgba(255, 202, 214, 0.80);
    background: linear-gradient(271deg, #FFEFF3 0.96%, #FFEAF3 99.18%);
    border-radius: 2/37.5rem;
    padding: 6/37.5rem 8/37.5rem;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    display: block;
    width: 85/37.5rem;
    height: 18/37.5rem;
    background: url(https://img.ltwebstatic.com/images3_ccc/2024/06/27/1c/17194918134dcdc0cb0d831848658d3e21d5e1d4c4.png) 50% 50% / cover no-repeat;
  }
  &__btn-wrapper {
    position: relative;
  }
  &__btn {
    display: block;
    max-width: 120/37.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 2/37.5rem 8/37.5rem;
    border: 1px solid #F82854;
    border-radius: 16/37.5rem;
    line-height: 14/37.5rem;
    font-size: 12px;
    font-family: 'SF-UI-Text-Bold';
    font-weight: 700;
    text-decoration: none;
    color: #F82854;
  }
  &__tooltip {
    position: absolute;
    right: 0 /* rtl:unset */;
    left: unset /* rtl:0 */;
    top: -13/37.5rem;
    padding: 1.5/37.5rem 4/37.5rem;
    line-height: 11/37.5rem;
    font-family: 'SF-UI-Text-Bold';
    font-weight: 700;
    color: #fff;
    font-size: 9px;
    border-radius: 2/37.5rem;
    background: linear-gradient(90deg, #F93776 0%, #D52BFF 100%);
    white-space: nowrap;
    &::after {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      border-width: 2px;
      border-style: solid;
      border-color: #D82BF1 transparent transparent transparent; 
      bottom: -4px;
      right: 8/37.5rem /* rtl:unset */;
      left: unset /* rtl:0.213333rem */;
    }
  }

  &__main {
    display: flex;
    align-items: center;
    height: 44/37.5rem;
    margin-top: 4/37.5rem;
  }
  &__desc {
    min-width: 61/37.5rem;
    flex: 1;
    margin-left: 8/37.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 28/37.5rem;
    line-height: 14/37.5rem;
    font-size: 12px;
    color: #000;
    font-family: 'SF-UI-Text-Regular';
    text-align: center;
    word-break: break-word;
  }
  &__coupon-list {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &__coupon {
    &:not(:first-child) {
      margin-left: 4/37.5rem;
    }
  }
  &__coupon-content {
    height: 44/37.5rem;
    position: relative;
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 6/37.5rem;
  }
  &__coupon-discount-wrapper {
    width: 100%;
  }
  &__coupon-discount {
    font-size: 14px;
    line-height: 16/37.5rem;
    color: #F82854;
    font-family: 'SF-UI-Text-Bold';
    font-weight: 700;
    text-align: center;
    &.free-shipping-coupon {
      font-size: 12px;
    }
    &.no-countdown-style {
      max-height: 32/37.5rem;
    }
  }
  &__coupon-badge {
    position: absolute;
    padding: 1/37.5rem 2/37.5rem;
    line-height: 9/37.5rem;
    top: 0;
    right: 0 /* rtl:unset */;
    left: unset /* rtl:0 */;
    color: #F82854;
    font-size: 9px;
    background-color: #FFD2DC;
    border-radius: 0 6/37.5rem;
    font-family: 'SF-UI-Text-Regular';
    direction: ltr /* rtl:ltr */;
  }
  &__coupon-countdown {
    font-size: 10px;
    font-family: 'SF-UI-Text-Regular';
    line-height: 1;
    color: #F82854;
  }
  &__coupon-130 {
    .fusion-super-save__coupon-content {
      width: 130/37.5rem;
      background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/06/28/e0/1719544763c47af2359883ac5f437a067255c3e5d2.png);
    }
  }
  &__coupon-160 {
    .fusion-super-save__coupon-content {
      width: 160/37.5rem;
      background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/07/12/c1/1720766275926b9478f7a25a08ebc66a7047e5ac46.png);
    }
  }
  &__coupon-140 {
    .fusion-super-save__coupon-content {
      width: 140/37.5rem;
      background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/06/28/17/1719545079d8044c731bc21d4d6b8b59e8a6406525.png);
    }
  }
  &__coupon-88 {
    .fusion-super-save__coupon-content {
      width: 88/37.5rem;
      background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/06/28/28/17195451265d5f3992f548d6016319d1ec091be357.png);
    }
  }
  &__coupon-80 {
    .fusion-super-save__coupon-content {
      width: 80/37.5rem;
      background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/06/28/51/1719545263f822b4146406b0154494309e61cfeaf6.png);
    }
  }
  &__coupon-85 {
    .fusion-super-save__coupon-content {
      width: 85/37.5rem;
      background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/06/28/51/1719545263f822b4146406b0154494309e61cfeaf6.png);
    }
  }
  &__more-coupons {
    position: relative;
    padding-bottom: 4/37.5rem;
    .fusion-super-save__coupon-content {
      z-index: 1;
      height: 40/37.5rem;
    }
    &::before {
      content: '';
      position: absolute;
      width: 84.4444%;
      height: 39/37.5rem;
      left: 50%;
      transform: translateX(-50%);
      border: 0.45px solid #FFD2DC;
      border-radius: 6/37.5rem;
      background-color: #FFF9FC;
      bottom: 0;
      z-index: 0;
    }
  }
  &__saved-text {
    width: 140/37.5rem;
    background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/06/28/ba/171955373487ff0fedff3dc4bf018c781b5ecc55be.webp);
    padding: 0 38/37.5rem 0 6/37.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 14/37.5rem;
    font-family: 'SF-UI-Text-Regular';
    color: #000;
    text-align: center;
  }
}
</style>
