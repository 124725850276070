var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showElement == 'showCouponList')?_c('div',{staticClass:"page-wrap"},[_c('img',{staticClass:"page-wrap__tip",attrs:{"src":_vm.tipImg}}),_vm._v(" "),_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
      id: '1-25-2-1009',
      data: { coupon_id: _vm.totalList.map(item => item.id).toString()},
    }),expression:"{\n      id: '1-25-2-1009',\n      data: { coupon_id: totalList.map(item => item.id).toString()},\n    }"}],staticClass:"coupon-container"},_vm._l((_vm.totalList),function(item,index){return _c('div',{directives:[{name:"tap",rawName:"v-tap",value:({
        id: '1-25-2-1010',
        data: { coupon_id: item.id, position: index + 1 },
      }),expression:"{\n        id: '1-25-2-1010',\n        data: { coupon_id: item.id, position: index + 1 },\n      }"}],key:index,ref:"couponContainerRef",refInFor:true,staticClass:"card-wrap",class:_vm.getCardWrapClass(item.counponType),on:{"click":function($event){return _vm.handleClickCounpon(item, index)}}},[([1,2,33].includes(item.counponType))?[(item.counponType == 1)?[_c('div',{staticClass:"card-wrap__logo_new-person"},[_c('div',{staticClass:"card-wrap__logo-title"},[_c('div',[_vm._v(_vm._s(_vm.userCenterLanguage?.SHEIN_KEY_PWA_27133))])]),_vm._v(" "),_c('img',{attrs:{"src":_vm.newPersonImg}})])]:(item.counponType == 2)?[_c('img',{staticClass:"card-wrap__logo_shein-saver",attrs:{"src":_vm.lang == 'ar' ? _vm.sheinSaverImgAr : _vm.sheinSaverImg}})]:[_c('img',{staticClass:"card-wrap__logo_shein-club",attrs:{"src":_vm.lang == 'ar' ? _vm.sheinClubImgAr : _vm.sheinClubImg}})]]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card__content",attrs:{"data-s-lang":_vm.lang}},[_c('div',{staticClass:"card__coupon-price",domProps:{"innerHTML":_vm._s(item.content)}}),_vm._v(" "),_c('div',{staticClass:"card__min-price"},[_vm._v("\n            "+_vm._s(item.tip)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"handle",attrs:{"data-s-lang":_vm.lang}},[_c('div',{staticClass:"handle__content"},[_c('div',{staticClass:"handle__use"},[_vm._v("\n              "+_vm._s(_vm.userCenterLanguage?.SHEIN_KEY_PWA_27132)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"handle__cut-down-time",domProps:{"innerHTML":_vm._s(_vm.countDownTips(index, item.counponType))}})])])])],2)}),0)]):(_vm.showElement == 'showBanner')?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-25-2-1007',
    data: { is_login: _vm.isLogin ? 1 : 0 },
  }),expression:"{\n    id: '1-25-2-1007',\n    data: { is_login: isLogin ? 1 : 0 },\n  }"},{name:"tap",rawName:"v-tap",value:({
    id: '1-25-2-1008',
    data: { is_login: _vm.isLogin ? 1 : 0 },
  }),expression:"{\n    id: '1-25-2-1008',\n    data: { is_login: isLogin ? 1 : 0 },\n  }"}]},[_c('AppCcc',_vm._b({staticClass:"advertise"},'AppCcc',_vm.appCccConfig,false))],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }