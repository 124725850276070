import asyncStreamCenter from 'public/src/pages/user/service/asyncStreamCenter'

export const triggerLowPriorityTask = fn => {
  asyncStreamCenter.register(
    () => {
      requestIdleCallback(() => {
        fn()
      })
    },
    { scene: 'dashboard' }
  )
}
