import { ref } from 'vue'

export const useCccxConfig = (cccxConfig) => {
  const { moduleBackground, jumpUrl } = cccxConfig || {}
  const { langPath } = gbCommonInfo
  const moduleStyles = ref({
    backgroundImage: `url(${moduleBackground})`,
  })
  return {
    jumpUrl: `${langPath}${jumpUrl}`,
    moduleStyles,
  }
}