<template>
  <div
    v-cloak
    class="paid-vip-box fsp-element"
    @click="goToPrimeEvt"
  >
    <div
      class="paid-vip-ctn"
      :class="{
        'fusion-floor-style': isInFusionFloor,
      }"
    >
      <div
        class="atmosphere-bg"
        :style="{ backgroundImage: `url(${PUBLIC_CDN}${GB_cssRight ? '/pwa_dist/images/user/prime/ar-shein-club-layer-a3e5dbace8.png' : '/pwa_dist/images/user/prime/shein_club_left_layer-b66d4a3575.png'})` }"
      >
        <div class="paid-vip-header">
          <div class="paid-title">
            <img 
              class="paid-txt"
              :class="isExpireStatus ? '' : 'add-pr'" 
              :src="getPaidVipImgsEvt('paid_vip_logo_text')"
            />
            <div
              v-if="isExpireStatus && !isInFusionFloor"
              class="paid-txt__expire" 
            >
              {{ language.SHEIN_KEY_PWA_23198 }}
            </div>
          </div>
          <div
            :class="[loginAndPaidVip && source.buyWhen !== 3 ? 'paid-desc' :'paid-hd__desc']"
            @click.stop="isContinuingToPay ? continueToPay() : goToPrimeEvt()"
          >
            <div
              v-if="newPanelInfo?.isShowSpecialOffer"
              class="renew-discount__tips"
            >
              <div class="renew-tips__txt">
                {{ language.SHEIN_KEY_PWA_23169 }}
              </div>
              <img
                class="renew-tips__img"
                :src="getPaidVipImgsEvt('prime_renew_discount_bg_tips')"
              />
            </div>
            <span
              class="user-rights__prime-btn-text"
            >{{ isContinuingToPay ? language.SHEIN_KEY_PWA_28639 : newPanelInfo.btn }}</span>
            <i
              v-if="loginAndPaidVip && source.buyWhen !== 3"
              class="suiiconfont sui_icon_more_right_16px_2"
            ></i>
          </div>
        </div>
        <div
          class="paid-vip-content"
          :class="{ 'join-club' :loginAndPaidVip }"
        >
          <!-- 引导提示 -->
          <p
            class="prime-tips"
            :class="newPanelInfo.class"
            v-html="newPanelInfo.text"
          >
          </p>
          <div
            v-if="showPrimeGoodsImg"
            class="prime-entry-imgs"
          >
            <div
              v-for="item,idx in source.entryImgs"
              :key="idx"
              class="prime-entry-img"
            >
              <img :src="cutImg(item.img_url, 120, 'unCut=0#max=120')" />
              <div
                v-if="primeGoodsDiscount"
                class="prime-entry-img__discount-info"
              >
                {{ primeGoodsDiscount }}
              </div>
            </div>
          </div>
          <template v-else>
            <div
              v-if="rightConfigList.length === 2"
              class="paid-vip-second"
            >
              <div class="right-icon">
                <img
                  v-for="(item, index) in rightConfigList"
                  :key="index"
                  :src="getRightIconEvt(item)"
                  alt=""
                />
              </div>
            </div>
            <div
              v-if="rightConfigList.length === 3"
              class="paid-vip-third"
            >
              <div class="right-icon">
                <img
                  v-for="(item, index) in rightConfigList"
                  :key="index"
                  :src="getRightIconEvt(item)"
                  alt=""
                />
              </div>
            </div>
            <div
              v-if="rightConfigList.length > 3"
              class="paid-vip-four"
            >
              <div class="right-icon">
                <img
                  v-for="(item, index) in rightConfigList"
                  :key="index"
                  :src="getRightIconEvt(item)"
                  alt=""
                />
              </div>
              <div class="four-item-more">
                <div
                  class="name-split"
                  :style="{ backgroundImage: `url(${getPaidVipImgsEvt('paid_vip_split')})` }"
                ></div>
                <div class="name-box name-text">
                  {{ language.SHEIN_KEY_PWA_21765 }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- 续费弹窗 -->
    <div @click.stop="">
      <personer-renew-dialog
        from="me"
        :language="language"
        :buy-when="source?.buyWhen"
        :is-show.sync="isShowRenewDialog"
        :source="source"
        :config-data="renewDialogConfig"
      />
    </div>

    <!-- 回本动效弹窗 -->
    <div @click.stop="">
      <pay-back-dialog
        from="me"
        :paid-detail="source.primeInfo"
        :language="language"
        :buy-when="source.buyWhen"
        :is-show="isShowBackDialog"
        :is-trialing="isTrialIng"
        :user-info="source.user"
        :is-discount-by-renew="isDiscountByRenew"
        @close-handle="renewHandle"
      />
    </div>
  </div>
</template>
<script>
import { template, transformImg, getLocalStorage } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { transfromAndCutImg } from 'public/src/services/resource'
import debug from 'debug'

const logger = debug('prime:panel')
daEventCenter.addSubscriber({ modulecode: '1-16-1' })
daEventCenter.addSubscriber({ modulecode: '1-25-7' })

let { langPath, PUBLIC_CDN, GB_cssRight, RENEW_DIALOG_DELAY } = gbCommonInfo
const PREFETCH_IMGS = []
export default {
  name: 'PaidVipDetail',
  components: {
    PayBackDialog: () => import(/* webpackChunkName: "pay-back-dialog" */ /* @vite-ignore */ 'public/src/pages/user/child_pages/prime/components/payBackDialog/index.vue'),
    personerRenewDialog: () => import(/* webpackChunkName: "personer-renew-dialog" */ /* @vite-ignore */ 'public/src/pages/user/child_pages/prime/components/personerRenewDialog/index.vue'),
  },
  props: {
    userInfo: {
      type: Object,
      default: () => ({})
    },
    source: {
      type: Object,
      default: () => ({})
    },
    isRouterLeaveFlag: {
      type: Boolean,
      default: false
    },
    sharedData: {
      type: Object,
      default: () => ({})
    },
    context: {
      type: String,
      default: 'independent',
    },
    fusionFloorPosition: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      PUBLIC_CDN,
      GB_cssRight,
      isShowBackDialog: false,
      newPanelInfo: {},
    }
  },
  computed: { 
    language () {
      return this.source?.language || {}
    },
    // 是否试用中
    isTrialIng () {
      return this.source?.primeInfo?.product_info?.product_type == 1 && this.source?.isPaid
    },
    isExpireStatus () {
      return this.source?.buyWhen == 2
    },
    // 是否有续费优惠折扣
    isDiscountByRenew () {
      if (![2, 3].includes(this.source.buyWhen)) return 0
      return this.source?.recommendProduct?.product_price_info?.price_discount_rate || 0
    },
    // 是否有待支付订单
    isContinuingToPay() {
      return this.source?.unpaid_link_billno && this.source?.unpaid_product_code
    },
    // 已登录且是付费会员
    loginAndPaidVip() {
      return this.source?.user?.member_id && this.source?.isPaid
    },
    // 非付费会员权益信息长度
    rightConfigList() {
      return this.sharedData?.sheinClubMemberRights || []
    },
    isOverZeroAmount() {
      return this.source?.primeInfo?.billno_enjoy_privilege_info?.req_currency_price > 0
    },
    isInFusionFloor() {
      return this.context === 'fusion-floor'
    },
    reportParams () {
      const { primeInfo, buyWhen, canTrial } = this.source || {}
      let button_content
      if (this.isContinuingToPay) {
        button_content = 3
      } else {
        if (this.newPanelInfo.btn == this.language.SHEIN_KEY_PWA_23563) {
          button_content = 1
        } else if (this.newPanelInfo.btn == this.language.SHEIN_KEY_PWA_23160) {
          button_content = 2
        } else {
          button_content = 0
        }
      }

      const fusionFloorPosition = this.context === 'fusion-floor' ? this.fusionFloorPosition : ''

      if (this.loginAndPaidVip) {
        let { price_usd = 0 } = this.source?.primeInfo?.billno_enjoy_privilege_info || {}
        return {
          button_content,
          buy_when: buyWhen,
          prime_level: primeInfo?.product_info?.product_cycle_type,
          total_saving: this.isOverZeroAmount ? price_usd : 0,
          prime_promotion: this.isDiscountByRenew ? 1 : 0,
          expiring_flag: buyWhen == 3 ? 1 : 0,
          renew_flag: [2, 3].includes(buyWhen) ? 1 : 0,
          prime_tp: primeInfo?.product_info?.product_type,
          prime_days: primeInfo?.product_info?.product_cycle_days,
          position: fusionFloorPosition,
        }
      } else {
        const prime_tp = canTrial ? 1 : this.source?.recommendProduct.product_type
        const prime_days = canTrial
          ? this.source?.trialProductCycleDays
          : this.source?.recommendProduct.product_cycle_days
        return {
          button_content,
          buy_when: buyWhen,
          prime_level: buyWhen == 2 ? -1 : 0,
          total_saving: 0,
          prime_promotion: this.isDiscountByRenew ? 1 : 0,
          expiring_flag: '-',
          renew_flag: [2, 3].includes(buyWhen) ? 1 : 0,
          prime_tp,
          prime_days,
          position: fusionFloorPosition,
        }
      }
    },
    newPanelKeyMap() {
      const { siteLength, membLength, isPaid, buyWhen, recommendProduct, calcSaveInfo = {}, primeInfo } = this.source
      // https://modao.cc/flow/hBaVXxlarz25tcFsOwoQrz 依据产品prd文档, 根据流程图规则，将产品文案的所有流程关系列出来
      return {
        // 0 下标表示未登录，1 下标表示已登录，以下同理
        isLogin: {
          value: isLogin(),
          nextKey: ['siteProducts', 'isPaid']
        },
        // 是否有站点可购产品包
        siteProducts: {
          value: siteLength !== 0,
        },
        // 是否是会员
        isPaid: {
          value: isPaid,
          nextKey: ['productList', 'fullMember']
        },
        // 是否是正式会员
        fullMember: {
          value: primeInfo?.product_info?.product_type === 0,
          nextKey: ['enjoyPrivilegePrice', 'buyWhen']
        },
        // 是否是临期会员
        buyWhen: {
          value: buyWhen == 3,
          nextKey: ['enjoyPrivilegePrice', 'enjoyPrivilegePrice']
        },
        // 是否有当前省额
        enjoyPrivilegePrice: {
          value: primeInfo?.billno_enjoy_privilege_info?.req_currency_price > 0,
          nextKey: buyWhen == 3 ? ['discountRate', 'discountRate'] : []
        },
        // 是否有用户可购产品包
        productList: {
          value: membLength !== 0,
          nextKey: ['siteProducts', 'firstBuy']
        },
        // 是否首次购买
        firstBuy: {
          value: buyWhen === 1,
          nextKey: ['discountPrice', 'discountRate']
        },
        // 是否有历史省额
        discountPrice: {
          value: primeInfo?.discount_summary_info?.discount_price > 0,
          nextKey: ['discountRate', 'discountRate']
        },
        // 是否有购买优惠活是否有续费优惠
        discountRate: {
          value: recommendProduct?.product_price_info?.price_discount_rate > 0,
          nextKey: buyWhen === 1 ? ['orderAmountBuy30'] : ['probationaryMemberBuy30', 'probationaryMemberBuy30']
        },
        // 近30天支付金额
        orderAmountBuy30: {
          value: calcSaveInfo?.total_order_amount > 0,
        },
        // 临期会员30天
        probationaryMemberBuy30: {
          value: primeInfo?.product_info?.end_time -  Math.round(new Date().getTime() / 1000) <= (primeInfo?.product_info?.renew_style_days || 30) * 24 * 60 * 60
        }
      }
    },
    newPanelTextMap() {
      // https://modao.cc/flow/hBaVXxlarz25tcFsOwoQrz 依据产品prd文档，根据true = 1, false = 0 的规则，将产品文案的所有情况列出来
      const { language = {}, calcSaveInfo = {}, primeInfo, recommendProduct, curDiscountValue, isPaid } = this.source || {}
      
      const total_saving_amount_with_symbol = calcSaveInfo?.total_saving_amount_with_symbol
      // 无购买优惠
      const price_discount_rate = recommendProduct?.product_price_info?.price_discount_rate

      // 产品包优惠率
      const productRateText = price_discount_rate ?  (+price_discount_rate  * 1000 / 10) + '%' : curDiscountValue
      // 优惠价
      const specialPriceWithSymbol = recommendProduct?.product_price_info?.special_price_with_symbol
      // 到达价格
      const arrivalPriceWithSymbol = recommendProduct?.product_price_info?.arrival_price_with_symbol
      // 会费折扣金额
      const reducePriceWithSymbol = recommendProduct?.product_price_info?.reduce_price_with_symbol
      // 总折扣金额
      const totalDiscountPriceWithSymbol = recommendProduct?.product_price_info?.total_discount_price_with_symbol
      // 历史总省额
      const historyDiscountPrice = primeInfo?.discount_summary_info?.discount_price
      // 历史总省额带符号
      const historyDiscountPriceWithSymbol = primeInfo?.discount_summary_info?.discount_price_with_symbol

      // 免邮项
      const freeShippingItem = (isPaid ? primeInfo : recommendProduct)?.right_config_list?.find(item => item.right_type_code === 'FREE_SHIPPING_TIMES')
      // 优惠券张数
      const couponTotal = freeShippingItem?.coupon_code_list?.reduce((total, nextItem) => {
        return total += (nextItem?.total_sheets || 0)
      }, 0)
      // 免邮券价值
      const couponValueWithSymbol = freeShippingItem?.coupon_value_with_symbol || ''
      // 有会费折扣
      const hasTotalDiscountPrice = this.source?.recommendProduct?.product_price_info?.total_discount_price > 0
      // 近30天支付金额
      const orderAmountBuy30 = calcSaveInfo?.total_order_amount > 0

      return {
      // 未登录 有可够产品包
        '01': { // 未生效、已退订兜底文案
          btn: language.SHEIN_KEY_PWA_26050,
          languageTemp: couponValueWithSymbol.length > 0 ? language.SHEIN_KEY_PWA_27049 : language.SHEIN_KEY_PWA_27277,
          data: couponValueWithSymbol.length > 0 ? [productRateText, couponTotal, couponValueWithSymbol] : [curDiscountValue]
        },
        // 登录状态 1 开头
        // 不是付费会员 10
        '1001': { // 已登录、不是会员、没有用户产品包、有站点可够产品包
          btn: language.SHEIN_KEY_PWA_26050,
          languageTemp: couponValueWithSymbol.length > 0 ? language.SHEIN_KEY_PWA_27049 : language.SHEIN_KEY_PWA_27277,
          data: couponValueWithSymbol.length > 0 ? [productRateText, couponTotal, couponValueWithSymbol] : [curDiscountValue]
        },
        '1010000': { // 未生效、已退订兜底文案
          btn: language.SHEIN_KEY_PWA_26050,
          languageTemp: orderAmountBuy30 ? (language.SHEIN_KEY_PWA_26048) : (couponValueWithSymbol.length > 0 ? language.SHEIN_KEY_PWA_27049 : language.SHEIN_KEY_PWA_27277),
          data: orderAmountBuy30 ? ([total_saving_amount_with_symbol]) : (couponValueWithSymbol.length > 0 ? [productRateText, couponTotal, couponValueWithSymbol] : [curDiscountValue])
        },
        '1010010': {
          btn: language.SHEIN_KEY_PWA_26050,
          languageTemp: hasTotalDiscountPrice ? (language.SHEIN_KEY_PWA_27047) : (couponValueWithSymbol.length > 0 ? language.SHEIN_KEY_PWA_27049 : language.SHEIN_KEY_PWA_27277),
          data: hasTotalDiscountPrice ? ([totalDiscountPriceWithSymbol, arrivalPriceWithSymbol]) : (couponValueWithSymbol.length > 0 ? [productRateText, couponTotal, couponValueWithSymbol] : [curDiscountValue]),
          isShowSpecialOffer: hasTotalDiscountPrice ? true : false,
        },
        '10111': { // 已登录、不是会员、有用户可够产品包、是首次购买、有购买优惠
          // 未生效、已退订优惠金额>0展示且展示优惠金额
          btn: language.SHEIN_KEY_PWA_26050,
          languageTemp: language.SHEIN_KEY_PWA_27047,
          data: [totalDiscountPriceWithSymbol, arrivalPriceWithSymbol],
          isShowSpecialOffer: true,
        },
        '101101': { // 已登录、不是会员、有用户可够产品包、是首次购买、没有有购买优惠、近30天支付>0
          // 未生效、已退订优惠金额=0且近30天支付>0
          btn: language.SHEIN_KEY_PWA_26050,
          languageTemp: language.SHEIN_KEY_PWA_26048,
          data: [total_saving_amount_with_symbol]
        },
        '101100': { // 已登录、不是会员、有用户可够产品包、是首次购买、没有有购买优惠、近30天支付=0
          // 未生效、已退订兜底文案
          btn: language.SHEIN_KEY_PWA_26050,
          languageTemp: couponValueWithSymbol.length > 0 ? language.SHEIN_KEY_PWA_27049 : language.SHEIN_KEY_PWA_27277,
          data: couponValueWithSymbol.length > 0 ? [productRateText, couponTotal, couponValueWithSymbol] : [curDiscountValue],
        },
        '1010001': { 
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: couponValueWithSymbol.length > 0 > 0 ? language.SHEIN_KEY_PWA_27051 : language.SHEIN_KEY_PWA_27276,
          data: couponValueWithSymbol.length > 0 ? [productRateText, couponTotal, couponValueWithSymbol] : [productRateText],
          expired: true,
        },
        '101000': { 
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: couponValueWithSymbol.length > 0 > 0 ? language.SHEIN_KEY_PWA_27051 : language.SHEIN_KEY_PWA_27276,
          data: couponValueWithSymbol.length > 0 ? [productRateText, couponTotal, couponValueWithSymbol] : [productRateText],
          expired: true,
        },
        '110011': { // 已登录、不是会员、有用户可够产品包、不是首次购买、没有历史省额、有续费优惠
          // 已过期、无省额优惠金额>0展示且展示优惠金额
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: language.SHEIN_KEY_PWA_27054,
          data: [totalDiscountPriceWithSymbol, arrivalPriceWithSymbol],
          isShowSpecialOffer: true,
          expired: true
        },
        '110010': { // 已登录、不是会员、有用户可够产品包、不是首次购买、没有历史省额、有续费优惠SHEIN_KEY_PWA_27050
          // 已过期、无省额优惠金额>0展示且展示优惠金额
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: language.SHEIN_KEY_PWA_27054,
          data: [totalDiscountPriceWithSymbol, arrivalPriceWithSymbol],
          isShowSpecialOffer: true,
          expired: true
        },
        '101001': { // 已登录、不是会员、有用户可够产品包、不是首次购买、没有历史省额、有续费优惠
          // 已过期、无省额优惠金额>0展示且展示优惠金额
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: language.SHEIN_KEY_PWA_27054,
          data: [reducePriceWithSymbol, specialPriceWithSymbol],
          isShowSpecialOffer: true,
          expired: true
        },
        '1010011': { // 已登录、不是会员、有用户可够产品包、不是首次购买、没有历史省额、有续费优惠
          // 已过期、无省额优惠金额>0展示且展示优惠金额
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: language.SHEIN_KEY_PWA_27054,
          data: [totalDiscountPriceWithSymbol, arrivalPriceWithSymbol],
          isShowSpecialOffer: true,
          expired: true
        },
        '101011': { // 已登录、不是会员、有用户可够产品包、不是首次购买、有历史省额、有续费优惠
          // 已过期、有省额优惠金额>0展示且展示优惠金额
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_27052],
          data: [[historyDiscountPrice, reducePriceWithSymbol, specialPriceWithSymbol]],
          isShowSpecialOffer: true,
          expired: true
        },
        '101010': { // 已登录、不是会员、有用户可够产品包、不是首次购买、有历史省额、没有续费优惠
          // 已过期、有省额优惠金额=0
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: couponValueWithSymbol.length > 0 ? language.SHEIN_KEY_PWA_27053 : language.SHEIN_KEY_PWA_27275,
          data: couponValueWithSymbol.length > 0 ? [historyDiscountPrice, reducePriceWithSymbol, couponTotal, couponValueWithSymbol] : [historyDiscountPrice, reducePriceWithSymbol]
        },
        '1010101': { // 已登录、不是会员、有用户可够产品包、不是首次购买、有历史省额、没有续费优惠
          // 已过期、有省额优惠金额=0
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: couponValueWithSymbol.length > 0 ? language.SHEIN_KEY_PWA_27053 : language.SHEIN_KEY_PWA_27275,
          data: couponValueWithSymbol.length > 0 ? [historyDiscountPriceWithSymbol, productRateText, couponTotal, couponValueWithSymbol] : [historyDiscountPriceWithSymbol, productRateText]
        },
        // 是付费会员 11
        '11100': { // 已登录、是付费会员、是正式会员、不是临期会员、没有当前省额
          // 生效中-临期天数>30天-无省额
          btn: language.SHEIN_KEY_PWA_26063,
          languageTemp: couponValueWithSymbol.length > 0 ? language.SHEIN_KEY_PWA_27060 : language.SHEIN_KEY_PWA_27277,
          data: couponValueWithSymbol.length > 0 ? [productRateText, couponTotal] : [curDiscountValue]
        },
        '11101': { // 已登录、是付费会员、是正式会员、不是临期会员、有当前省额
          // 生效中-临期天数>30天-有省额
          btn: language.SHEIN_KEY_PWA_26063,
          languageTemp: language.SHEIN_KEY_PWA_26053,
          data: [primeInfo?.billno_enjoy_privilege_info?.req_currency_price_with_symbol],
          class: 'prime-tips__active'
        },
        '110001': {
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_27055],
        },
        '1111001': { // 已登录、是付费会员、是正式会员、是临期会员、没有当前省额、没有购买优惠
          // 生效中-临期天数≤30天-无省额优惠金额=0
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_27055],
        },
        '1111000': { // 已登录、是付费会员、是正式会员、是临期会员、没有当前省额、无购买优惠
          // 生效中-临期天数≤30天-无省额优惠金额>0展示且展示优惠金额 不是临期会员<30天
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: language.SHEIN_KEY_PWA_27058,
        },
        '110000': { // 已登录、是付费会员、是正式会员、是临期会员、没有当前省额、无购买优惠
          // 生效中-临期天数≤30天-无省额优惠金额>0展示且展示优惠金额 不是临期会员<30天
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: language.SHEIN_KEY_PWA_27058,
        },
        '1111010': { // 已登录、是付费会员、是正式会员、是临期会员、没有当前省额、有购买优惠
          // 生效中-临期天数≤30天-无省额优惠金额>0展示且展示优惠金额 不是临期会员<30天
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_27050],
          data: [[totalDiscountPriceWithSymbol, arrivalPriceWithSymbol]],
          isShowSpecialOffer: true,
        },
        '1111011': { // 已登录、是付费会员、是正式会员、是临期会员、没有当前省额、有购买优惠
          // 生效中-临期天数≤30天-无省额优惠金额>0展示且展示优惠金额 是临期会员<30天
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_27050],
          data: [[totalDiscountPriceWithSymbol, arrivalPriceWithSymbol]],
          isShowSpecialOffer: true,
        },
        '1100': { // 已登录、是付费会员、不是正式会员、没有当前省额
          // 生效中-临期天数>30天-无省额优惠金额=0
          btn: language.SHEIN_KEY_PWA_26063,
          // languageTemp: language.SHEIN_KEY_PWA_27049,
          // data: [productRateText, couponTotal, couponValueWithSymbol],
          languageTemp: couponValueWithSymbol.length > 0 ? language.SHEIN_KEY_PWA_27049 : language.SHEIN_KEY_PWA_27277,
          data: couponValueWithSymbol.length > 0 ? [productRateText, couponTotal, couponValueWithSymbol] : [curDiscountValue, couponTotal]
        },
        '1101': { // 已登录、是付费会员、不是正式会员、有当前省额
          // 生效中-临期天数>30天-省额优惠金额>0
          btn: language.SHEIN_KEY_PWA_26063,
          languageTemp: language.SHEIN_KEY_PWA_26053,
          data: [primeInfo?.billno_enjoy_privilege_info?.req_currency_price_with_symbol],
          class: 'prime-tips__active'
        },
        '11000': { // 已登录、是付费会员、不是正式会员、没有当前省额、没有购买优惠
          // 生效中-临期天数≤30天-无省额优惠金额=0
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_26058, language.SHEIN_KEY_PWA_26061],
          data: [],
        },
        '11001': { // 已登录、是付费会员、不是正式会员、没有当前省额、有购买优惠
          // 生效中-临期天数≤30天-无省额优惠金额>0展示且展示优惠金额
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: language.SHEIN_KEY_PWA_26060,
          data: [productRateText],
          isShowSpecialOffer: true,
        },
        '11010': { // 已登录、是付费会员、不是正式会员、有当前省额、没有购买优惠
          // 生效中-临期天数≤30天-有省额优惠金额=0
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_26053, language.SHEIN_KEY_PWA_26061],
          data: [[primeInfo?.billno_enjoy_privilege_info?.req_currency_price_with_symbol]],
        },
        '11011': { // 已登录、是付费会员、不是正式会员、有当前省额、有购买优惠
          // 生效中-临期天数≤30天-有省额优惠金额>0展示且展示优惠金额
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [ language.SHEIN_KEY_PWA_26053, language.SHEIN_KEY_PWA_26051],
          data: [[primeInfo?.billno_enjoy_privilege_info?.req_currency_price_with_symbol], [productRateText]],
          isShowSpecialOffer: true,
        },
        '110100': {
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_27059],
          data: [[primeInfo?.billno_enjoy_privilege_info?.req_currency_price_with_symbol]],
        },
        '1111100': { // 已登录、是付费会员、不是正式会员、有当前省额、没有购买优惠
          // 生效中-临期天数≤30天-有省额优惠金额=0 不是临期会员<30天
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_27059],
          data: [[primeInfo?.billno_enjoy_privilege_info?.req_currency_price_with_symbol]],
        },
        '110101': { 
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_27057],
          data: [[primeInfo?.billno_enjoy_privilege_info?.req_currency_price_with_symbol]],
        },
        '1111101': { // 已登录、是付费会员、不是正式会员、有当前省额、没有购买优惠
          // 生效中-临期天数≤30天-有省额优惠金额=0 是临期会员<30天
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_27057],
          data: [[primeInfo?.billno_enjoy_privilege_info?.req_currency_price_with_symbol]],
        },
        '111111': { // 已登录、是付费会员、不是正式会员、有当前省额、有购买优惠
          // 生效中-临期天数≤30天-有省额优惠金额>0展示且展示优惠金额
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_26053, language.SHEIN_KEY_PWA_26051],
          data: [[primeInfo?.billno_enjoy_privilege_info?.req_currency_price_with_symbol], [productRateText]],
          isShowSpecialOffer: true,
        },
        '110110': {
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_27056],
          data: [[primeInfo?.billno_enjoy_privilege_info?.req_currency_price_with_symbol, totalDiscountPriceWithSymbol, arrivalPriceWithSymbol]],
          isShowSpecialOffer: true,
        },
        '110111': {
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_27056],
          data: [[primeInfo?.billno_enjoy_privilege_info?.req_currency_price_with_symbol, totalDiscountPriceWithSymbol, arrivalPriceWithSymbol]],
          isShowSpecialOffer: true,
        },
        '1010111': {
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_27056],
          data: [[historyDiscountPriceWithSymbol, totalDiscountPriceWithSymbol, arrivalPriceWithSymbol]],
          isShowSpecialOffer: true,
        },
        '1111110': { // 已登录、是付费会员、不是正式会员、有当前省额、有购买优惠
          // 生效中-临期天数>30天-有省额优惠金额>0展示且展示优惠金额 不是临期会员<30天
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_27056],
          data: [[primeInfo?.billno_enjoy_privilege_info?.req_currency_price_with_symbol, totalDiscountPriceWithSymbol, arrivalPriceWithSymbol]],
          isShowSpecialOffer: true,
        },
        '1111111': { // 已登录、是付费会员、不是正式会员、有当前省额、有购买优惠
          // 生效中-临期天数≤30天-有省额优惠金额>0展示且展示优惠金额 是临期会员<30天
          btn: language.SHEIN_KEY_PWA_26057,
          languageTemp: [language.SHEIN_KEY_PWA_27056],
          data: [[primeInfo?.billno_enjoy_privilege_info?.req_currency_price_with_symbol, totalDiscountPriceWithSymbol, arrivalPriceWithSymbol]],
          isShowSpecialOffer: true,
        },
      }
    },
    isShowRenewDialog() {
      const result = !this.isRouterLeaveFlag && this.handleIsShowRenewDialog()
      return result
    },
    renewDialogConfig() {
      const [endTime, currentTime] = [
        this.source?.primeInfo?.product_info?.total_end_time || 0,
        Date.parse(new Date()) / 1000
      ]
      // 回本倍数
      const roi = this.source?.primeInfo?.billno_enjoy_privilege_info?.roi ?? '-'
      // prime_tp：产品类型，0-正式版、1-体验版
      const prime_tp = this.source?.primeInfo?.product_info?.product_type ?? '-'
      // 付费会员总到期时间
      const total_end_time = this.source?.primeInfo?.product_info?.total_end_time ?? '-'
      // 有会费折扣或会费券
      const hasTotalDiscountPrice = this.source?.recommendProduct?.product_price_info?.total_discount_price > 0
      // 是否过期
      const isExpired = endTime - currentTime < 0
      let tempObj = {
        seconds: Math.abs(endTime - currentTime), // 倒计时秒数 大于3天（259200秒）不展示倒计时
        isCoupons: hasTotalDiscountPrice,
        title: this.language[isExpired ? 'SHEIN_KEY_PWA_29307' : 'SHEIN_KEY_PWA_29306'],
        prime_promotion: this.isDiscountByRenew ? 1 : 0, // 组件曝光埋点需要
        roi, // 组件曝光埋点需要
        prime_tp,
        total_end_time,
        title2: this.language.SHEIN_KEY_PWA_29324, // 有倒计时的情况下增加一个标题
        ...this.getHasSaveTxt(hasTotalDiscountPrice, roi, isExpired),
        ...this.getContentAndBtnTxt(hasTotalDiscountPrice)
      }
      return tempObj
    },
    // 展示免费权益商品图
    showPrimeGoodsImg({ source, primeGoodsDiscount }) {
      return source?.entryImgs?.length >= 2 && primeGoodsDiscount
    },
    primeGoodsDiscount({ source, language }) {
      const disoucntVal = (source.curDiscountValue || '').replace('%', '')
      const tempStr = language.SHEIN_KEY_PWA_32141 || '{0}% off'
      if (!disoucntVal) return ''
      return template(disoucntVal, tempStr)
    }
  },
  watch: {
    source: {
      async handler () {
        this.afterGetData()
      },
      deep: true,
      immediate: true
    }
  },
  async created () {
    // 预取图片
    this.handleImgPreload()
    this.getPaidVipImgsEvt()
  },
  methods: {
    template,
    // 省额提示
    getHasSaveTxt (hasTotalDiscountPrice, roi, isExpired) {
      const {
        req_currency_price: hasSaved,
        req_currency_price_with_symbol: savedWithSymbol
      } = this.source?.primeInfo?.billno_enjoy_privilege_info || {}
      return {
        isExpired,
        tipTitle: roi >= 1 ? this.template(roi, this.language.SHEIN_KEY_PWA_29314) : '',
        subTitle: !hasSaved
          ? this.language[hasTotalDiscountPrice ? 'SHEIN_KEY_PWA_29310' : 'SHEIN_KEY_PWA_29311']
          : this.template(savedWithSymbol, this.language[isExpired ? 'SHEIN_KEY_PWA_29309' : 'SHEIN_KEY_PWA_29308'])
      }
    },
    // 续费引导文案、续费按钮
    getContentAndBtnTxt (hasTotalDiscountPrice) {
      const {
        total_discount_price_with_symbol: totalDiscountPriceWithSymbol,
        arrival_price_with_symbol: priceSymbol
      } = this.source?.recommendProduct?.product_price_info || {}
      const primeGift = this.source?.recommendProduct?.gift_info_list?.filter?.(item => item.gift_type == 1) || []
      return {
        content1: hasTotalDiscountPrice
          ? this.language.SHEIN_KEY_PWA_29315
          : this.language[primeGift?.length > 0 ? 'SHEIN_KEY_PWA_29317' : 'SHEIN_KEY_PWA_29319'],
        content2: hasTotalDiscountPrice
          ? this.template(totalDiscountPriceWithSymbol, this.language.SHEIN_KEY_PWA_29316)
          : this.language[primeGift?.length > 0 ? 'SHEIN_KEY_PWA_29318' : 'SHEIN_KEY_PWA_29320'],
        btn: this.template(
          `<span class="coupon-price">${priceSymbol}</span>`,
          this.language[hasTotalDiscountPrice ? 'SHEIN_KEY_PWA_29312' : 'SHEIN_KEY_PWA_29313'],
        )
      }
    },
    handleIsShowRenewDialog() {
      // 判断续费弹窗abt是否开启 已经登录 buy_when 不为空
      const abtBranch = this.source?.abtInfo?.ClubRenewMePop?.param?.ClubRenewMePop == 'on'
      let flag = isLogin() && abtBranch && [1, 2, 3, 4].includes(this.source.buyWhen)
      const result = !this.isRouterLeaveFlag && flag
      if(!result) {
        return false
      }
      // 插入场景
      // 当以下条件同时满足时，不弹付费会员续费引导弹窗：
      // ① 无省额
      // /v2/User/prime/paidDetail接口的req_currency_price字段 < 0
      // ② 无会费价&会费券
      // /v2/User/prime/queryMemberBuyProduct接口的total_discount_price < 0
      // ③ 无买赠活动
      // /v2/User/prime/queryMemberBuyProduct接口的gift_type≠1
      const req_currency_price = this.source?.primeInfo?.billno_enjoy_privilege_info?.req_currency_price || 0
      const total_discount_price = this.source?.recommendProduct?.product_price_info?.total_discount_price || 0
      const flag1 = req_currency_price <= 0
      const flag2 = total_discount_price <= 0
      let flag3
      const currentGift = this.source?.recommendProduct?.gift_info_list?.[0] || {}
      if(currentGift?.gift_type != 1) {
        flag3 = true
      }
      if(flag1 && flag2 && flag3) {
        return false
      }

      // 缓存校验
      const { overdue_rules = [], near_expired_rules = [] } = RENEW_DIALOG_DELAY
      // 2. 判断是临期还是逾期，
      // 若差值≥0，则为临期状态，将差值换算为临期天数
      // 若临期天数为0~3天，用户每天首次访问个人中心，均弹出1次
      // 若临期天数为4~30天，用户每间隔5天 首次访问个人中心，弹出1次
      // 若差值<0，则为逾期状态，将差值换算为逾期天数
      // 若逾期天数为0~7天，用户每间隔2天 首次访问个人中心，弹出1次
      // 若逾期天数为8~21天，用户每间隔5天 首次访问个人中心，弹出1次
      // 若逾期天数为22~60天，用户每间隔10天 首次访问个人中心，弹出1次
      const endTime = this.source?.primeInfo?.product_info?.total_end_time * 1000
      const currentTime = new Date().getTime()
      
      const time = endTime - currentTime
      let days = time / 1000 / 60 / 60 / 24
      let currentRule
      if(days >= 0) {
        currentRule = near_expired_rules.find(rule => days >= rule.left_bound && days < rule.right_bound)
      } else {
        days = -days
        currentRule = overdue_rules.find(rule => days >= rule.left_bound && days < rule.right_bound)
      }
      if(currentRule) {
        flag = this.timeCheck(currentRule.interval_time * 1000, currentTime, time >= 0 ? 'nearExpired' : 'expired')
      } else {
        flag = false
      }
      return flag
    },
    // 校验缓存时间是否失效
    timeCheck(time, currentTime, type) {
      const { SiteUID, user } = gbCommonInfo
      const cacheKey = (SiteUID || '') + '-' + (user.member_id || '') + '-'
      let cacheTimeKey
      // 临期key
      const nearExpiredKey = cacheTimeKey = cacheKey + 'primeV2RenewDialog'
      // 逾期key
      const ExpiredKey = cacheTimeKey = cacheKey + 'primeV2ExpiredRenewDialog'
      if(type == 'nearExpired') {
        cacheTimeKey = nearExpiredKey
      } else {
        cacheTimeKey = ExpiredKey
      }
      
      const cacheTime = +getLocalStorage(cacheTimeKey)

      if(!cacheTime) { // 不存在缓存，触发弹窗
        return true
      } else {
        // 先判断缓存是否失效，如果未失效，不触发弹窗,返回false
        // 如果缓存失效,触发弹窗

        // 当前时间 2024年10月12日 10：20:30
        // 0天失效，2024年10月13日 10:20:30
        // 24 小时/天 × 60 分钟/小时 × 60 秒/分钟 × 1000 毫秒/秒
        // 缓存的开始时间
        const cacheDayStartTime = new Date(cacheTime).getTime()
        // 当天的开始时间
        const currentDayStartTime = new Date(currentTime).getTime()
        // 命中缓存
        if(currentDayStartTime - cacheDayStartTime < time) {
          return false
        } else {
          return true
        }
      }
    },
    // 获取面板信息
    getPanelInfoMap(key){
      const panelInfo = this.newPanelTextMap[key]
      if(!panelInfo) {
        return {}
      }
      const text = this.genLanguageText(panelInfo.languageTemp, panelInfo.data)
      return {
        ...panelInfo,
        text
      }
    },
    genLanguageText(langText, data) {
      if(Array.isArray(langText)) {
        let text = ''
        langText.forEach((item, idx) => {
          text += ' ' + this.genLanguageText(item, data?.[idx])
        })
        return text
      }
      const getSpanTxt = txt => `<span>${txt}</span>`
      const texts = data?.map(item=> getSpanTxt(item)) || ''
      return this.template(...texts, langText)
    },
    getPanelNodeMap(nextKey) {
      return this.newPanelKeyMap[nextKey]
    },
    getPanelKey() {
      const nodeKeyArr = ['isLogin']
      const nodeValueArr = []
      let step = 1 // 控制循环次数，避免无限循环
      let traceInfos = ''
      while(step < 10) {
        step++
        const nextKey = nodeKeyArr[nodeKeyArr.length - 1]
        if(nextKey) {
          const nextNodeInfo = this.getPanelNodeMap(nextKey)
          const _value = Number(Boolean(nextNodeInfo.value))
          traceInfos += `${nextKey}:${_value}-> `
          nodeValueArr.push(_value)
          if(nextNodeInfo?.nextKey?.[_value]) {
            nodeKeyArr.push(nextNodeInfo.nextKey[_value])
          } else {
            break
          }
        } else {
          break
        }
      }
      logger('traceInfos: %s%s', traceInfos, nodeValueArr.join(''))
      return nodeValueArr.join('')
    },
    async handlePanelInfo (){
      const key = this.getPanelKey()
      const panelInfo = this.getPanelInfoMap(key)
      this.newPanelInfo = panelInfo
      logger('panelInfo', panelInfo)
    },
    renewHandle () {
      daEventCenter.triggerNotice({
        daId: '1-16-1-26',
        extraData: this.reportParams
      })
      this.$router.push(`${langPath}/user/prime?productsource=me_popup&renew=1`)
    },
    afterGetData () {
      this.$nextTick(() => {
        this.handlePanelInfo()
        setTimeout(() => {
          this.payBackHandle()
        }, 1000)
      })
      daEventCenter?.triggerNotice({
        daId: '1-16-1-25',
        extraData: this.reportParams
      })
    },
    payBackHandle () {
      if (!this.isRouterLeaveFlag && !this.isShowRenewDialog) {
        // 续费弹窗优先级高于回本弹窗
        this.isShowBackDialog = true
      }
    },
    continueToPay() {
      const billno = this.source?.unpaid_link_billno
      if (billno) {
        daEventCenter.triggerNotice({
          daId: '1-16-1-26',
          extraData: this.reportParams
        })
        this.$router.push(`${langPath}/user/orders/detail/${billno}`)
      }
    },
    getRightIconEvt(item = {}) {
      return this.transformImg({ img: item?.right_type_icon || '' })
    },
    goToPrimeEvt () {
      daEventCenter.triggerNotice({
        daId: '1-16-1-26',
        extraData: this.reportParams
      })
      let query = 'productsource=me'
      if (this.source?.canTrial) {
        query += '&trial=1'
      }
      if (this.source?.user?.member_id) {
        this.$router.push(`${langPath}/user/prime?${query}`)
      } else {
        SHEIN_LOGIN.show({ show: true, bi: 'club', cb: () => {
          this.$router.push(`${langPath}/user/prime?productsource=me`)
        } })
      }
    },
    getPaidVipImgsEvt (type) {

      const imgsMap = {
        paid_vip_logo: PUBLIC_CDN + '/pwa_dist/images/pay_vip/paid_vip_logo-088fc507b3.svg',
        // paid_vip_logo_ar: PUBLIC_CDN + '/pwa_dist/images/pay_vip/paid_vip_logo_ar-a578769e95.svg',
        paid_vip_logo_text: PUBLIC_CDN + '/pwa_dist/images/pay_vip/paid_vip_logo_text-21a27b7d3f.png',
        paid_vip_split: PUBLIC_CDN + '/pwa_dist/images/pay_vip/pay_vip_split-eea3fc8ee3.png', // PNG 更小
        prime_renew_discount_bg: PUBLIC_CDN + '/pwa_dist/images/user/prime/sui_img_limitedoffertag_atmosphere-f9524b35fe.svg',
        prime_renew_discount_bg_tips: PUBLIC_CDN + '/pwa_dist/images/pay_vip/shein-club-bottom-arrow-1aa4262348.png',
      }
      return imgsMap[type]
    },
    handleImgPreload() {
      const list = [
        // GB_cssRight ? 'paid_vip_logo_ar' : 'paid_vip_logo',
        'paid_vip_logo',
        'paid_vip_logo_text',
        'paid_vip_split',
        'prime_renew_discount_bg',
        'prime_renew_discount_bg_tips',
      ]

      list.forEach(type => {
        const url = this.getPaidVipImgsEvt(type)
        if (PREFETCH_IMGS[url]) return
        PREFETCH_IMGS[url] = new Image()
        PREFETCH_IMGS[url].src = url
      })
      
    },
    transformImg,
    cutImg(imgUrl, designWidth, exp) {
      const { RESOURCE_SDK = null } = gbCommonInfo
      const {
        deviceData = '',
        isSupportWeb = '',
        isSupprotCut = false,
        sceneMap = {}
      } = RESOURCE_SDK || {}

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        sceneMap,
        exp
      }

      return transfromAndCutImg(cutData)
    },
  }
}
</script>
<style lang="less">
/* stylelint-disable selector-max-specificity, declaration-no-important  */

.paid-vip-ctn{
  margin: 0 .32rem;
  height: 1.7067rem;
  border-radius: .16rem;
  border: 1px solid rgba(255, 208, 184, 0.50);
  background: linear-gradient(270deg, rgba(255, 186, 151, 0.40) 0%, rgba(255, 186, 151, 0.29) 100%), #FFF;

  .paid-vip-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: 1.8133rem;
    margin: 0 .2667rem;
    height: .96rem;
    color: #873C00;

    .prime-tips {
      margin-right: .32rem;
      padding: .16rem 0;
      max-width: 6.7467rem;
      max-height: .96rem;
      font-size: .32rem;
      font-weight: 400;
      font-style: normal;
      line-height: .3733rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      span {
        font-size: 12px;
        font-weight: 700;
        color: #FA6338;
      }

      &__active {
        span {
          color: #873C00;
          font-size: .5333rem;
          font-weight: 700;
        }
      }
    }
    .right-icon {
      display: flex;

      img {
        width: .5333rem;
        height: .5333rem;
          &:not(:last-child) {
          margin-right: .1067rem;
        }
      }
    }
  }

  .prime-entry-imgs {
    display: flex;
  }
  .prime-entry-img {
    width: 42/37.5rem;
    height: 42/37.5rem;
    border: 1/37.5rem solid #fff;
    border-radius: 2/37.5rem;
    margin-right: 2/37.5rem;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 2/37.5rem;
    }
    &__discount-info {
      position: absolute;
      width: 100%;
      height: 13/37.5rem;
      left: 0;
      bottom: 0;
      border-bottom-left-radius: 2/37.5rem;
      border-bottom-right-radius: 2/37.5rem;
      background-color: #873C00;
      color: #fff;
      font-size: 9/37.5rem;
      font-family: SF-UI-Text-Regular;
      line-height: 13/37.5rem;
      text-align: center;
      .line-camp(1);
      word-break: break-all;
    }
  }

  .paid-vip-header {
    background-position: 0 0;
    background-size: cover;
    display: flex;
    align-items: center;
    min-height: 53/75rem;
    z-index: 2;/* stylelint-disable-line declaration-property-value-blacklist */
    position: relative;
    .padding-l(234/75rem);
    .padding-r(16/75rem);
    position: relative;

    .paid-desc{
      margin: 0;
      position: absolute;
      right: 0;
      color: #873C00;
      line-height: 28/75rem;
      padding: 10/75rem 0;
      .margin-l(18/75rem);
      .txt-r();

      i {
        .fr();
      }

      span{
        .txt-r();
      }
    }
  }
  .paid-title{
    display: flex;
    align-items: center;
    height: .6933rem;
    padding: 0px 0 0px .2133rem;
    border-radius: .16rem 0px .32rem 0px;
    background: linear-gradient(180deg, rgba(255, 204, 178, 0.25) 0%, rgba(255, 175, 134, 0.25) 100%);
    flex-shrink: 0;
    position: absolute;
    left: 0;

    .add-pr {
      margin-right: .2133rem;
    }
  }
  .paid-title__expire {
    width: 5.12rem;
    justify-content: flex-start;
    .margin-l(.16rem);
  }
  .paid-txt{
    background-position: 0 0;
    background-size: contain;
    background-repeat: no-repeat;
    width: 180/75rem;
    height: 38/75rem;
  }
  .paid-txt__expire {
    background: #E5E5E5;
    border: 1px solid rgba(187, 187, 187, 0.5);
    border-radius: .56rem;
    padding: .0267rem 6px;
    color: #767676;
    font-size: .2667rem;
    transform: scale(.8);
  }

  &.fusion-floor-style {
    border-radius: 2/37.5rem;
    border: 0.5px solid rgba(255, 208, 184, 0.70);
    background: linear-gradient(274deg, rgba(255, 211, 189, 0.40) 0.6%, rgba(255, 186, 151, 0.29) 96.44%), #FFF;
    .user-rights__prime-btn-text {
      display: block;
      max-width: 104/37.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      font-family: 'SF-UI-Text-Bold';
      font-weight: 700;
      line-height: 14/37.5rem;
    }
    .atmosphere-bg {
      background: none;
      background-image: none !important;
    }
    .paid-title {
      background: none;
    }
  }

  .paid-vip-first{
    display: flex;
    justify-content: space-around;
    .right-icon {
      padding: 6px 0;
    }
  }

  .paid-vip-second{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20/75rem 0;
    .second-item{
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 0 32/75rem;
    }
    .last-second-item{
      .border-l(1px,rgba(159, 79, 45, .15))
    }
    .img-box{
      .padding-r(9/75rem);
    }
    .img-item{
      width: 40/75rem;
      height: 40/75rem;
      display: block;
      border: none;
      object-fit: contain;
    }
    .name-box{
      line-height: 28/75rem;
      word-break: break-word;
    }
  }
  .paid-vip-third{
    display: flex;
    // align-items: center;
    // justify-content: center;
    // padding: 20/75rem 0;
    // .third-item{
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   padding: 0 24/75rem;
    // }
    // .img-item{
    //   width: .5333rem;
    //   height: .5333rem;
    //     &:not(:last-child) {
    //     margin-right: .1067rem;
    //   }
    // }
    // .name-box{
    //   line-height: 28/75rem;
    //   word-break: break-word;
    // }
  }

  .paid-vip-four{
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 20/75rem 25/75rem;
    .img-item{
      width: 40/75rem;
      height: 40/75rem;
      display: block;
      border: none;
      object-fit: contain;
      margin: 0 auto;
    }
    .name-box{
      line-height: 28/75rem;
      word-break: break-word;
    }
    .four-item-bene{
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
    .four-item{
      flex: 1;
      .padding-r(28/75rem);
      text-align: center;
    }

    .four-item-more{
      width: 160/75rem;
      padding: 0 28/75rem;
      text-align: center;
      position: relative;
      padding-top: .1rem;
      display: flex;
      align-items: center;
    }
    .name-text{
      line-height: .4rem;
    }
    .name-split{
      position: absolute;
      width: 9/75rem;
      height: 60/75rem;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .left(0);
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}

.atmosphere-bg {
  width: 100%;
  height: 100%;
  background: url("/pwa_dist/images/user/prime/shein_club_left_layer-b66d4a3575.png") no-repeat left / contain;
}

.paid-hd__desc {
  position: absolute;
  padding: .0533rem .2133rem;
  border-radius: .6933rem;
  border: .0267rem solid #873C00;
  color: #873C00;
  text-align: center;
  font-size: .32rem;
  font-style: normal;
  font-weight: 600;
  font-family: "SF UI Display";
  line-height: normal;
  .right(0);
  margin: .1333rem .2133rem 0 0;
  .paid-desc {
    
    img {
      width: .3733rem;
      height: .3733rem;
      margin: 0 .2667rem 0 .1067rem;
    }
  }
}
.renew-discount__tips {
  position: absolute;
  top: -77%;
  .right(0);
  .renew-tips__txt {
    white-space: nowrap;
    border-radius: .0533rem;
    background: linear-gradient(90deg, #C96E3F 48.82%, #EAA27E 100%);   
    color: #FFFFFF;
    padding: .0533rem .1067rem;
    text-align: center;
    font-family: 'SF UI Display';
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .renew-tips__img {
    width: .16rem !important; 
    height: .10667rem !important; 
    position: absolute;
    bottom: -.10667rem;
    .left(80%);
  }
}
</style>
