<script lang="jsx">
const { appLanguage, language: gbLanguage, SiteUID } = gbCommonInfo
import JourneyMixin from 'public/src/pages/components/journey-branch/mixins'
import { htmlDecode } from '@shein/common-function'

export default {
  mixins: [JourneyMixin],
  props: {
    pos: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      wakeupData: {
        before: this.beforeWakeupAppEvent,
        data: {
          url_from: 'OrderOrPoints'
        },
      },
      installed: false,
      closedByUser: false,
      timer: -1,
      currentBanner: {},
    }
  },
  computed: {
    btnText() {
      const defaulGetText = gbLanguage.SHEIN_KEY_PWA_15257.toUpperCase()
      const defaulOpenText = gbLanguage.SHEIN_KEY_PWA_16270.toUpperCase()
      const text = (this.installed ? this.currentBanner.inst_button_info : this.currentBanner.noinst_button_info) || (this.installed ? defaulOpenText : defaulGetText)
      return htmlDecode({ text })
    },
    context() {
      const text = this.pos === 'order' ? gbLanguage.SHEIN_KEY_PWA_18428 : gbLanguage.SHEIN_KEY_PWA_18429
      return htmlDecode({ text })
    }
  },
  async mounted() {
    await this.calfingerKey()
    await this.getInfos()
    this.installed = window.JOURNEY_BRANCH.installed
    this.matchJoureny()
    appEventCenter.$on('branchInstallChange', this.branchInstallChangeEvent)
    setTimeout(() => {
      this.exposePopup()
    }, 1000)
  },
  // eslint-disable-next-line no-unused-vars
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.timer)
  },
  created() {
    this.currentJourney.journey_name = this.wakeupData.data.url_from
    this.journeyName = this.wakeupData.data.url_from
    this.checkCloseStatus()
  },
  methods: {
    matchJoureny() {
      const { journeis } = window.JOURNEY_BRANCH
      if (journeis && journeis.length) {
        const find = journeis.find(el => el.journey_name === this.wakeupData.data.url_from)
        if (find) {
          const banner = this.filterBanner(find)
          this.currentBanner = banner || {}
        }
      }
    },
    branchInstallChangeEvent(installed) {
      this.installed = installed
    },
    checkCloseStatus() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.checkCloseStatus()
      }, 60000)
      const key = 'next_show_banner_userindex_' + SiteUID + '_' + appLanguage
      const value = localStorage.getItem(key)
      if (value && /^\d+$/.test(value)) {
        if (+value > Date.now()) {
          this.closedByUser = true
          return
        }
      }
    },
    beforeWakeupAppEvent() {
      sa('send', {
        activity_name: 'click_download_lock',
        activity_param: {
          isinstall: this.installed ? 1 : 0
        }
      })
    },
    exposePopup() {
      sa('send', {
        activity_name: 'expose_download_lock',
        activity_param: {
          order_or_points: this.pos,
          isinstall: this.installed ? 1 : 0
        }
      })
      this.branchBannerShow()
    },
    closeHandle() {
      this.closedByUser = true
      localStorage.setItem(
        'next_show_banner_userindex_' + SiteUID + '_' + appLanguage,
        Date.now() + 24 * 3600000 + ''
      )
      sa('send', {
        activity_name: 'click_download_lock_close',
        activity_param: {}
      })
      this.branchBanerClose()
    }
  },
  render() {
    return (
      <div
        class="download-guid"
        v-show={!this.closedByUser}
      >
        <div class="download-guid__inner">
          <div class="download-guid__content">
            { this.context }
          </div>
          <div class="download-guid__button" v-call-app="wakeupData">
            { this.btnText }
          </div>
          <div
            class="download-guid__close"
            vOn:click={this.closeHandle}>
            <i class="iconfont icon-close"></i>
          </div>
        </div>
      </div>
    )
  }
}
</script>


