import schttp from 'public/src/services/schttp'

// 获取收藏店铺列表数据
export const followListsApi = (params) => {
  return schttp({
    url: '/api/user/store_following/get',
    method: 'get',
    params,
  })
}

// 获取收藏店铺列表数据
export const followListsPageInit = () => {
  return schttp({
    url: '/api/user/store_following/init',
    method: 'get',
  })
}

// 获取收语言数据
export const languageTextGetApi = (params) => {
  return schttp({
    url: '/api/user/store_following/text/get',
    method: 'get',
    params,
  })
}

// 批量取关店铺
export const deleteStoreFollowingApi = (data) => {
  return schttp({
    url: '/api/user/store_following/delete',
    method: 'post',
    data,
  })
}
// 查看店铺是否有上新/活动
export const checkedHasStoreNewArrivalApi = () => {
  return schttp({
    url: '/api/user/store_following/refresh/get',
    method: 'get',
  })
}

// 获取推荐店铺列表
export const getRecommendStoreListApi = (params, signal = null) => {
  return schttp({
    url: '/api/user/store_following/rec_stores',
    method: 'get',
    params,
    signal,
  })
}
